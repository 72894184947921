import request from '../utils/requrst'
// 跳转微信公众号

export function getherfe(params) {
  return request({
    url: '/api/tenxun/jump',
    method: 'get',
    params
  })
}
// 