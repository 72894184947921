<template>
<div style="background-color: #f5f5f5; width: 100%;">
   <div class="bigDiv">
<!-- <div class="topImg">
</div> -->
<!-- <div class="bodys">
  <div class="imgAs"><img src="../../assets/images/Tiktok/dyB/640.png" alt=""></div>
  <div class="names">易经学堂</div>
  <div class="buzhou">课程领取步骤:1关注公众号,2扫描二维码...</div>
  <div class="submit" @click="wxClick">  <el-button type="success" ref="submit">点击领取</el-button></div>
  <div class="tipsA">点击领取,添加老师好友,免费领取五天直播课</div>
  <div class="tipsB">页面内容由XXXXX文化科技有限公司提供</div>
</div> -->
 </div>
</div>
</template>

<script>
import { getherfe } from "@/api/tengxun";
 export default {

 
  data () {
   return {
    
biz: '1'
   }
  },
  created(){
    this.biz= this.$route.query.id || '1'
    this.wxClick()
  },
  mounted(){
  },
  methods: { 
async wxClick(){
  const query = {
   id: this.biz
  }
 const {data} = await getherfe(query)
window.location.href = data.data.url
  
}
  },

  watch: {

  },
  computed: {

  },
  filters: {

  }
 }
</script>

<style scoped lang='less'>
  .bigDiv{
    margin: 0 auto;
    min-height: 600px;
    background-color: #fff;
  }
.topImg{
  width: 100%;
  height: 200px;
background-color: red;
}
.bodys{
  width: 100%;
  text-align: center;
  overflow: hidden;
  .imgAs{
    width: 60px;
    height: 60px;
    margin:  10px auto;
    overflow: hidden;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .names{
    font-size: 18px;
    font-weight: 700;
  }
  .buzhou{
    font-size: 14px;
    color: #666;
    margin:  10px auto;

  }
  .submit{
    .el-button{
      margin: 10px auto;
      width: 150px;
      background-color: #07c160;
      border: none;
      margin-bottom: 20px;
    }
  
  }
  .tipsA{
    font-weight: 700;
    font-size: 16px;
    color: #de342e;
  }
  .tipsB{
    color: #666;
    font-size: 12px;
    margin: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv{
    width: 800px;
    margin: 0 auto;
    min-height: 900px;
    background-color: #fff;
  }
  .topImg{
  width: 100%;
  height: 350px;
background-color: red;
}
.bodys{
  width: 100%;
  text-align: center;
  overflow: hidden;
  .imgAs{
    width: 50px;
    height: 50px;
    margin:  10px auto;
    overflow: hidden;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .names{
    font-size: 18px;
    font-weight: 700;
  }
  .buzhou{
    font-size: 14px;
    color: #666;
    margin:  10px auto;

  }
  .submit{
    .el-button{
      margin: 10px auto;
      width: 150px;
      background-color: #07c160;
      border: none;
      margin-bottom: 20px;
    }
  
  }
  .tipsA{
    font-weight: 700;
    font-size: 16px;
    color: #de342e;
  }
  .tipsB{
    color: #666;
    font-size: 12px;
    margin: 10px;
    margin-top: 25px;
    margin-bottom: 30px;
  }
}
}
</style>